<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="row">
				<div class="col-md-8">
					<a-radio-group :value="filters.status" @change="handleStatusChange" style="padding: 0">
						<a-radio-button value="-1"> Canceladas </a-radio-button>
						<a-radio-button value="0"> Solicitudes </a-radio-button>
						<a-radio-button value="1"> Cotizadas </a-radio-button>
						<a-radio-button value="5"> Enviadas </a-radio-button>
						<a-radio-button value="2"> Autorizadas </a-radio-button>
					</a-radio-group>
				</div>
				<div class="col-md-4 text-right">
					<a-button class="btn btn-info" icon="plus" @click="onAddRecord">Nueva cotización</a-button>
					<a-button class="btn btn-success ml7" icon="reload" @click="initModule" />
				</div>
			</div>
			<div class="row pt10">
				<div class="col-md-12">
					<a-table :columns="columns" :dataSource="searchText == '' ? impressmentList : impressmentListFiltered" rowKey="id" bordered>
						<div slot="fullName" slot-scope="record" class="text-left">
							{{ record.fullName }}
							<div>{{ moment(record.created_at).format('DD-MM-YYYY HH:mm') }} hrs.</div>
						</div>
						<div slot="customer_name" slot-scope="record" class="text-left">
							{{ record.customer_name }}
						</div>
						<div slot="description" slot-scope="record" class="text-left">
							<div v-for="(element, index) in record.impressmentElements" :key="index">{{ `${element.quantity}` }}x {{ element.description }}</div>
						</div>
						<div slot="action" slot-scope="record">
							<div>
								<button class="btn btn-info ant-btn-sm ant-btn-icon-only ml7" @click="onEditRecord(record.id)" v-if="record.status == 0">
									<a-icon type="edit" />
								</button>
								<button class="btn btn-danger ant-btn-sm ant-btn-icon-only ml7" @click="onCancelRecord(record.id)" v-if="record.status > 0 && record.status != 2">
									<a-icon type="close" />
								</button>
								<button class="btn btn-success ant-btn-sm ant-btn-icon-only ml7" @click="confirmQuote(record.id)" v-if="record.status == 5">
									<a-icon type="check" />
								</button>
							</div>
							<div class="pt5">
								<button class="btn btn-info ant-btn-sm ant-btn-icon-only ml7" @click="sendQuotation(record.id, 'email')" v-if="record.status == 1 || record.status == 5">
									<a-icon type="mail" />
								</button>
								<button class="btn btn-info ant-btn-sm ant-btn-icon-only ml7" @click="sendQuotation(record.id, 'whatsapp')" v-if="record.status == 1 || record.status == 5">
									<a-icon type="message" />
								</button>
								<div class="pointer pt5" v-if="record.status > 0" @click="downloadQuotation(record.id)">ver cotización</div>
								<div class="pt5" v-if="record.status == 2 && record.order_id">
									<b>Pedido #{{ record.order_id }}</b>
								</div>
							</div>
						</div>
					</a-table>
				</div>
			</div>
		</a-spin>
		<a-modal :visible="modalQuotationView" title="Detalle de cotización" :closable="false" width="80%" v-if="modalQuotationView">
			<template slot="footer">
				<a-button key="back" @click="closeQuotationModal"> Cerrar </a-button>
			</template>
			<div class="col-md-12 pt10">
				<object :data="quotationBase64" type="application/pdf" width="100%" height="600px"></object>
			</div>
		</a-modal>
	</div>
</template>

<script>
//
import { mapGetters } from 'vuex'
import moment from 'moment-timezone'
import Swal from 'sweetalert2'
import _ from 'lodash'
import numeral from 'numeral'

export default {
	name: 'ImpressmentView',
	computed: {
		...mapGetters('impressment', ['spinnerLoader', 'spinnerLoaderLabel', 'impressmentList', 'filters']),
		...mapGetters('serviceOrders', ['serviceOrdersList']),
		...mapGetters('user', ['user']),
		userPermition() {
			// zem - 17
			// ismael - 1
			let allowedUserPermition = [1, 17]
			return !!allowedUserPermition.includes(this.user.user_id)
		},
		getImpressmentElements() {
			return this.actualRecord.impressmentElements.map((e, index) => {
				return {
					...e,
					index: index + 1,
				}
			})
		},
	},
	data() {
		return {
			searchText: '',
			impressmentListFiltered: [],
			modalQuotationView: false,
			quotationBase64: '',
			columns: [
				{
					title: '#',
					dataIndex: 'id',
					key: 'id',
					align: 'center',
					width: '10%',
				},
				{
					title: 'Solicitante',
					scopedSlots: { customRender: 'fullName' },
					width: '15%',
				},
				{
					title: 'Cliente/Distribuidor',
					scopedSlots: { customRender: 'customer_name' },
					width: '15%',
				},
				{
					title: 'Solicitud',
					scopedSlots: { customRender: 'description' },
					align: 'center',
					width: '50%',
				},
				{
					title: 'Acciones',
					scopedSlots: { customRender: 'action' },
					align: 'center',
					width: '10%',
				},
			],
			columnsElements: [
				{
					title: '#',
					align: 'center',
					scopedSlots: { customRender: 'index' },
				},
				{
					title: 'Cantidad',
					align: 'center',
					scopedSlots: { customRender: 'quantity' },
				},
				{
					title: 'SKU - Descripción',
					align: 'center',
					scopedSlots: { customRender: 'description' },
				},
				{
					title: 'Precio unitario',
					align: 'center',
					scopedSlots: { customRender: 'price' },
				},
				{
					title: 'Importe',
					align: 'center',
					scopedSlots: { customRender: 'importe' },
				},
			],
			modalView: false,
			actualRecord: {},
			selectedRowKeys: [],
		}
	},
	mounted() {
		this.initModule()
	},
	methods: {
		moment,
		numeral,
		handleStatusChange(e) {
			this.$store.commit('impressment/SET_STATE', {
				filters: {
					...this.filters,
					status: e.target.value,
				},
			})
			this.initModule()
		},
		onAddRecord() {
			this.$router.push('/impressment/create')
		},
		initModule() {
			this.$store.dispatch('impressment/GET', {
				status: this.filters.status,
			})
		},
		onEditRecord(id) {
			this.$router.push(`/impressment/${id}`)
		},
		downloadQuotation(id) {
			this.$store.dispatch('impressment/GENERATE_PDF', id).then((response) => {
				this.quotationBase64 = `data:application/pdf;base64,${response.data.base64}`
				setTimeout(() => {
					this.modalQuotationView = true
				}, 100)
			})
		},
		closeQuotationModal() {
			this.modalQuotationView = false
			this.quotationBase64 = ''
		},
		sendQuotation(id, method) {
			Swal.fire({
				title: 'Atención',
				text: `La cotización será enviada al ${method} del cliente/distribuidor. ¿Deseas continuar?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'Cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					let payload = {
						id,
						method: 'email',
					}
					await this.$store.dispatch('impressment/SEND_GENERATE_PDF', { payload, method })
					this.initModule()
				}
			})
		},
		onCancelRecord(id) {
			Swal.fire({
				title: 'Atención',
				text: '¿Deseas cancelar la cotización?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No',
			}).then((result) => {
				//
				if (result.isConfirmed) {
					this.$store.dispatch('impressment/DELETE', { id })
				}
			})
		},
		confirmQuote(id) {
			//
			this.actualRecord = _.cloneDeep(this.impressmentList.find((e) => e.id == id))

			Swal.fire({
				title: 'Atención',
				text: '¿Deseas autorizar la cotización?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then(async (result) => {
				if (result.isConfirmed) {
					// Se genera nuevo pedido
					await this.$store
						.dispatch('orders/CREATE', {
							impressment_id: this.actualRecord.id,
						})
						.then(async (responseImpressment) => {
							// Se autoriza una cotización
							await this.$store.dispatch('impressment/UPDATE_STATUS', {
								id: this.actualRecord.id,
								status: 2,
								order_id: responseImpressment.id,
							})
						})

					this.initModule()

					this.modalView = false
					this.actualRecord = {}
					this.selectedRowKeys = []
				}
			})
		},
		closeModal() {
			this.modalView = false
		},
		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys
		},
		onSearch(value) {
			if (value != '') {
				this.impressmentListFiltered = []
				value = value.toLowerCase()
				this.impressmentListFiltered = this.impressmentList.filter((obj) =>
					Object.values(obj).some((val) => {
						if (typeof val == 'string' && val.toLowerCase().includes(value)) {
							return true
						}
					}),
				)
			}
		},
	},
}
</script>

<style lang="scss">
.tableImpressmentElements {
	td {
		padding-top: 5px !important;
		padding-bottom: 5px !important;
	}
}
</style>